import React from 'react'
import styled from 'styled-components'
import InputFieldWithValidation from '../generic/InputFieldWithValidation'
import { ReactComponent as DesktopIcon } from "../../assets/icons/dekstop.svg"
import { isURL } from 'validator'
const DesktopConfig = ({
    desktop,
    setDesktop,
    mac, setMac,
    linux, setLinux,
    nonMobile }) => {

    return (
        <InputWrapper>
            <InputFieldWithValidation
                subtitle={nonMobile ? "Windows" : ""}
                titleIcon={<DesktopIcon />}
                placeholder={!nonMobile ? "Enter the link to redirect to on Windows device" : "Enter the link to redirect to on Web"}
                title={"Web (non-mobile devices)"}
                label={!nonMobile ? "The dynamic link will open this page if viewed from Web" : "The dynamic link will open this page if viewed from an Windows device"}
                bottomLine={!nonMobile}
                inputValue={desktop}
                valid={isURL(desktop)}
                handleInputChange={(e) => setDesktop(e.currentTarget.value)}
            />

            {nonMobile &&
                <InputFieldWithValidation
                    subtitle={"MacOS"}
                    placeholder={"Enter the link to redirect to on MacOS "}
                    label={"The dynamic link will open this page if viewed from an MacOS  device"}
                    inputValue={mac}
                    valid={isURL(mac)}
                    handleInputChange={(e) => setMac(e.currentTarget.value)}
                />
            }

            {nonMobile &&
                <InputFieldWithValidation
                    subtitle={"Linux"}
                    placeholder={"Enter the link to redirect to on Linux"}
                    label={"The dynamic link will open this page if viewed from an Linux  device"}
                    bottomLine
                    inputValue={linux}
                    valid={isURL(linux)}
                    handleInputChange={(e) => setLinux(e.currentTarget.value)}
                />

            }

        </InputWrapper>
    )
}

export default DesktopConfig

const InputWrapper = styled.div`
display:flex;
gap:17px;
flex-direction:column;
width:100%;
`