import { SQD_ENDPOINT } from "../../constants/constants";
import { POST } from "../API";

export const createFreeLinkAPICall = (
    data,
    onSuccess,
    onError
) => {

    POST(
        SQD_ENDPOINT,
        `/create`,
        data,
        onSuccess,
        onError
    );
};
