import React, { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { lightThemeColors } from "../../assets/style/colors";
import InputFieldWithValidation from "../generic/InputFieldWithValidation";
import { FACEBOOK, FILE, LINK, LINKEDIN, X } from "../../constants/constants";
import OptionSelectComponent from "../generic/OptionSelectComponent";
import { useDropzone } from "react-dropzone";
import PrimaryButton from "../generic/PrimaryButton";
import SocialMediaButtonsGroup from "../generic/SocialMediaButtonsGroup";
import FacebookCardPreview from "../GenerateLinkComponents/FacebookCardPreview";
import XCardPreview from "../generic/XCardPreview";
import LinkedInCardPreview from "../generic/LinkedInCardPreview";
import { isURL } from "validator";
import { motion } from "framer-motion";

const ConfigureSocialMediaPreview = ({
  title,
  setTitle,
  subtitle,
  setSubtitle,
  handleBackBtn,
  handleGenerateDynamicLink,
  pictureType,
  setPictureType,
  pictureLink,
  setPictureLink,
  files,
  setFiles,
  recaptchVerify
}) => {
  const [socialMediaCardType, setSocialMediaCardType] = useState(FACEBOOK);
  const [imgPreview, setImgPreview] = useState("");
  const ref = useRef(null);
  const [width, setWidth] = useState(0);

  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
    accept: {
      "image/*": [],
    },

    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });

  const OPTIONS_LIST = [
    {
      text: "Upload File",
      value: FILE,
    },
    {
      text: "Link",
      value: LINK,
    },
  ];

  const displaySocialMediaCard = () => {
    var displayCard = <></>;
    switch (socialMediaCardType) {
      case FACEBOOK:
        displayCard = (
          <FacebookCardPreview
            img={imgPreview}
            title={title}
            domain={"https://go.sdk.link"}
            subtitle={subtitle}
          />
        );
        break;

      case X:
        displayCard = (
          <XCardPreview
            img={imgPreview}
            title={title}
            domain={"https://go.sdk.link"}
            subtitle={subtitle}
          />
        );

        break;
      case LINKEDIN:
        displayCard = (
          <LinkedInCardPreview
            img={imgPreview}
            title={title}
            domain={"https://go.sdk.link"}
            subtitle={subtitle}
          />
        );
        break;

      default:
        break;
    }
    return displayCard;
  };

  useEffect(() => {
    if (pictureType === LINK) {
      setImgPreview(pictureLink);
    }

    if (pictureType === FILE && files) {
      setImgPreview(files[0].preview);
    }
  }, [files, pictureLink, pictureType]);



  const onResize = useCallback(() => {
    setWidth(window.innerWidth)
  }, []);

  React.useEffect(() => {
    window.addEventListener("resize", onResize);
    onResize();
    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, []);

  return (
    <Container>
      <Content
        initial={{ height: 0 }}
        animate={{ height: "100%" }}
        exit={{ height: 0 }}
        transition={{ duration: 0.4 }}
      >
        <InputWrapper>
          <TitleWrapper>
            <Title>Configure social media preview</Title>
            <Border>
              <BorderFill />
            </Border>
          </TitleWrapper>
          <InputFieldWithValidation
            placeholder={
              "Enter a title for your link to be presented on social media"
            }
            title={"Title"}
            noValidation
            inputValue={title}
            handleInputChange={(e) => setTitle(e.currentTarget.value)}
          />
          <InputFieldWithValidation
            placeholder={
              "Enter a subtitle for your link to be presented on social media"
            }
            title={"Subtitle"}
            noValidation
            inputValue={subtitle}
            handleInputChange={(e) => setSubtitle(e.currentTarget.value)}
          />
          <Group>
            <h1>Picture</h1>
          </Group>
          <OptionSelectComponent
            optionsList={OPTIONS_LIST}
            selectedOption={pictureType}
            setSelectedOption={setPictureType}
          />

          {pictureType === LINK ? (
            <InputFieldWithValidation
              placeholder={"Enter the link for your the social media asset"}
              inputValue={pictureLink}
              handleInputChange={(e) => setPictureLink(e.currentTarget.value)}
              valid={isURL(pictureLink)}
            />
          ) : (
            <DragAndDropWrapper className="container">
              <div {...getRootProps({ className: "dropzone" })}>
                <input {...getInputProps()} />
                {files ? (
                  <p>
                    {" "}
                    <span>Pick another one from your computer</span>
                  </p>
                ) : (
                  <p>
                    Drop a file or <span>pick one from your computer</span>
                  </p>
                )}
              </div>
            </DragAndDropWrapper>
          )}
          {width > 990 &&
            <ButtonsWrapper>
              <PrimaryButton
                onClick={handleBackBtn}
                styled={{
                  width: "100%",
                  padding: "15px",
                  background: lightThemeColors.background,
                  color: lightThemeColors.darkColor,
                }}
                text={"Back to redirects"}
              />
              <PrimaryButton
                onClick={recaptchVerify}
                styled={{
                  width: "100%",
                  padding: "15px",
                  background: lightThemeColors.darkColor,
                  color: lightThemeColors.background,
                }}
                text={"Generate dynamic link"}
              />
            </ButtonsWrapper>
          }
        </InputWrapper>

        <InfoContent>
          <Group>
            <h1>Preview</h1>
            <h2>This is how your link will look when posted on social media</h2>
          </Group>

          <Line>
            <SocialMediaButtonsGroup
              selectedOption={socialMediaCardType}
              setSelectedOption={setSocialMediaCardType}
            />
          </Line>
          {displaySocialMediaCard()}
        </InfoContent>
      </Content>

      {width < 991 &&
        <ButtonsWrapper>
          <PrimaryButton
            onClick={handleBackBtn}
            styled={{
              width: "100%",
              padding: "15px",
              background: lightThemeColors.background,
              color: lightThemeColors.darkColor,
            }}
            text={"Back to redirects"}
          />
          <PrimaryButton
            onClick={handleGenerateDynamicLink}
            styled={{
              width: "100%",
              padding: "15px",
              background: lightThemeColors.darkColor,
              color: lightThemeColors.background,
            }}
            text={"Generate dynamic link"}
          />
        </ButtonsWrapper>
      }
    </Container>
  );
};

export default ConfigureSocialMediaPreview;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 100%;
  overflow: hidden;
`;
const Content = styled(motion.div)`
  display: flex;
  gap: 90px;
  align-items: center;
  overflow: hidden;
  @media(max-width:990px){
  flex-direction:column;
    gap: 30px;
  }
`;
const Title = styled.div`
  font-size: 18px;
  font-weight: 700;
  line-height: 21.6px;
  text-align: left;
  color: ${lightThemeColors.primaryColor};
`;
const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 10px;
`;
const Border = styled.div`
  width: 100%;
  min-height: 3px;
  background: ${lightThemeColors.primaryColorFaded};
  border-radius: 1px;
`;
const BorderFill = styled.div`
  width: 50%;
  background: ${lightThemeColors.primaryColor};
  min-height: 3px;
  border-radius: 4px;
`;
const InputWrapper = styled.div`
  display: flex;
  gap: 20px;
  flex-direction: column;
  width: 100%;
`;
const InfoContent = styled.div`
  overflow: hidden;
  display: flex;
  gap: 20px;
  flex-direction: column;
  width: 100%;
  border-left: 1px solid rgba(238, 236, 235, 1);
  padding-left: 40px;
  height: fit-content;
  h2 {
    color: ${lightThemeColors.textColor};
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    text-align: left;
  }
     @media(max-width:990px){
  padding-left: 0px;
   border-left: none;
  }
`;
const Group = styled.div`
  display: flex;
  gap: 5px;
  flex-direction: column;
  h1 {
    color: ${lightThemeColors.darkColor};
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
  }
`;
const DragAndDropWrapper = styled.section`
  width: 100%;
  border: 1px dashed rgba(120, 118, 118, 0.24);
  background: #eceae8;
  min-height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  span {
    color: ${lightThemeColors.primaryColor};
  }
  p {
    cursor: pointer;
    color: ${lightThemeColors.inputFieldLabelColor};
    font-size: 12px;
    font-weight: 500;
    line-height: 12px;
    text-align: left;
  }
`;
const Line = styled.div`
  display: flex;
  gap: 15px;
  align-items: center;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  gap: 15px;
  align-items: center;
  margin-top:30px;

@media(max-width:990px){
      display: flex;
      width:100%;
}
`