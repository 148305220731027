import React from "react";
import styled from "styled-components";
import { lightThemeColors } from "../../assets/style/colors";


const ArticleCard = ({
  category,
  title,
  readingTime,
  background,
  img,
  style,
  onClick,
}) => {
  return (
    <Container onClick={onClick}>
      <BackgroundContainer style={style}>
        <img src={img} alt="article-img" loading="lazy" />
      </BackgroundContainer>

      <TextContainer>
        <p>
          {category ? category.toUpperCase() : ""} - {readingTime} MINUTE READ
        </p>
        <h3>{title}</h3>
      </TextContainer>
    </Container>
  );
};

export default ArticleCard;
const Container = styled.div`
  display: flex;
  width:100%;
  flex-direction: column;
  cursor: pointer;
  border-radius: 8px;
  padding: 20px;
  transition: 400ms all ease-out;
  overflow: hidden;
  &:hover {
    -webkit-box-shadow: 0px 0px 20px 10px rgba(31, 31, 31, 0.1);
    -moz-box-shadow: 0px 0px 20px 10px rgba(31, 31, 31, 0.1);
    box-shadow: 0px 0px 20px 10px rgba(31, 31, 31, 0.1);
    img {
      transform: scale(1.07);
    }
  }
  @media(max-width:768px){
    padding: 15px;
  }
  @media(max-width:425px){
    padding: 10px;
  }

@media(max-width:320px){
  &:hover{
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }

  padding: 0;
 
}
  min-width: 30%;
`;

const TextContainer = styled.div`

  h3 {
    color: ${lightThemeColors.darkColor};
    font-size: 18px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: -0.48px;
    margin: 12px 0 0 0;
  }

  p {
    color: rgba(31, 31, 31, 0.6);
    font-size: 12px;
    line-height: 16px;
    border-radius: 3px;
    background: #fff;

    width: fit-content;
    margin: 8px 0 0 0;
    margin-top: 12px;
  }
`;

const BackgroundContainer = styled.div`
  display: flex;
overflow:hidden;
border-radius: 8px;
  img {
    object-fit: content;
    border-radius: 8px;
    transition: 400ms all ease-out;
    border-radius: 10px;
    width: 100%;
    min-height: 200px;
  }
  position: relative;
`;
