import React, { useEffect } from 'react'
import styled from 'styled-components'
import Fold1 from '../components/Fold1/Fold1'
import Fold2 from '../components/Fold2/Fold2'
import Fold3 from '../components/Fold3/Fold3'
import Fold4 from '../components/Fold4/Fold4'
import Fold5 from '../components/Fold5/Fold5'
import Fold6 from '../components/Fold6/Fold6'
import { useSearchParams } from 'react-router-dom'





const LandingPage = () => {
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        const element = searchParams.get("scrollTo")
        if (element) {
            let scrollToElement = document.getElementById(element)
            scrollToElement.scrollIntoView({ behavior: "smooth" })
            setSearchParams((prev) => {
                prev.delete("scrollTo")
            })
        }
    }, [])

    return (
        <Container >
            <Fold1 />
            <Fold2 />
            <Fold3 />
            <Fold4 />
            <Fold5 />
            <Fold6 />
        </Container>
    )
}

export default LandingPage

const Container = styled.div`
display:flex;
flex-direction:column;
`