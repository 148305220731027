import React from 'react'
import styled from 'styled-components'
import { lightThemeColors } from '../../assets/style/colors'
import { DEFAULT_PALCEHOLDER_LINK } from '../../constants/constants'


const XCardPreview = ({ domain, title, subtitle, img }) => {
    return (
        <Card>
            <ImageWrapper>
                {img ? <img src={img} alt="social-media-preview" /> :
                    <img src={DEFAULT_PALCEHOLDER_LINK} alt="social-media-preview" />
                }
            </ImageWrapper>
            <InfoWrapper>
                <Title>{title ? title : "Dynamic link"}</Title>
                <Subtitle>{subtitle ? subtitle : "Dynamic links, attributions, and referrals across mobile and web platforms."}</Subtitle>
                <Domain>{domain}</Domain>
            </InfoWrapper>

        </Card>
    )
}

export default XCardPreview

const Card = styled.div`
display:flex;
flex-direction:column;
max-width:343px;
max-height:260px;
overflow:hidden;
height:100%;
`
const InfoWrapper = styled.div`
display:flex;
flex-direction:column;
border:1px solid rgba(0,0,0,0.15);
gap:1px;
padding:6px 8px;
border-radius: 0 0 5px 5px;
background:${lightThemeColors.socialMediaPreviewCard.xCardInfoBg};
height:100%;
`
const ImageWrapper = styled.div`
display:flex;

overflow:hidden;
border-radius:5px 5px 0 0;
height:100%;
width:100%;
justify-content:center;
align-items:center;
img{
height:100%;
width:100%;
object-fit:cover;
}
`
const Domain = styled.p`
color:${lightThemeColors.socialMediaPreviewCard.xCardDomainColor};
font-size: 9.16px;
font-weight: 400;
line-height: 12.42px;
text-transform:uppercase;
`
const Title = styled.h1`
color:${lightThemeColors.socialMediaPreviewCard.primaryColor};
font-size: 12px;
font-weight: 600;
line-height: 16px;
`
const Subtitle = styled.p`
color:${lightThemeColors.socialMediaPreviewCard.primaryColor};
font-size: 9px;
font-weight: 500;
line-height: 12px;
text-align: left;
`
