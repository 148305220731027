import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { RouterProvider } from 'react-router-dom';
import { router } from './router/router';
import { Toaster } from 'react-hot-toast';
import { useScroll } from 'framer-motion';


const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <Toaster
      position="bottom-right"
    />
    <RouterProvider router={router} />
  </React.StrictMode>
);

