import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { lightThemeColors } from "../../assets/style/colors";
import PrimaryButton from "../generic/PrimaryButton";

import { ReactComponent as Circle1 } from "../../assets/icons/circleAnimations/circle1.svg";
import { ReactComponent as Circle2 } from "../../assets/icons/circleAnimations/Circle2.svg";
import { ReactComponent as Circle3 } from "../../assets/icons/circleAnimations/Circle3.svg";
import { ReactComponent as Circle4 } from "../../assets/icons/circleAnimations/Circle4.svg";
import { ReactComponent as Circle5 } from "../../assets/icons/circleAnimations/Circle5.svg";
import { ReactComponent as AssetTop } from "../../assets/icons/circleAnimations/CircleAssetTop.svg";
import { ReactComponent as AssetBottom } from "../../assets/icons/circleAnimations/CircleAssetBottom.svg";
import { ReactComponent as AssetRight } from "../../assets/icons/circleAnimations/CircleAssetRight.svg";
import { ReactComponent as AssetLeft } from "../../assets/icons/circleAnimations/CircleAssetLeft.svg";
import { ReactComponent as AssetCenter } from "../../assets/icons/circleAnimations/CircleAssetCenter.svg";
import {
  scroll,
  useScroll,
  motion,
  useSpring,
  useTransform,
  useInView,
  animate,
  useAnimate,
} from "framer-motion";
import { Link, useOutletContext } from "react-router-dom";

const Fold1 = () => {

  const fold1Ref = useRef();

  const inView = useInView(fold1Ref)

  useEffect(() => {
    const element = document.getElementById("headerId")
    if (inView) {
      animate(element, { background: lightThemeColors.linkGeneratorBg })
    } else {
      animate(element, { background: lightThemeColors.background })
    }

  }, [inView])

  return (
    <FullPageContainer>
      <Container ref={fold1Ref} id="fold1Id">
        <TextWrapper>
          <Title>
            <span>All links lead to you.</span>
            <br /> Mobile linking, attribution and referrals.
          </Title>
          <Subtitle>
            Linksquared generates dynamic links, tracks attributions, and
            analyzes referrals, seamlessly directing users to your app or the
            app store for downloads.
          </Subtitle>
          <Line>
            <Link style={{ textDecoration: "none" }} to={"/create"}>
              <PrimaryButton
                text={"Try it out for free"}
                bgVariant={lightThemeColors.background}
                styled={{ color: lightThemeColors.darkColor }}
              />
            </Link>
            <Link style={{ textDecoration: "none" }} to={"/contact"}>
              <PrimaryButton
                text={"Request a demo"}
                bgVariant={lightThemeColors.darkColor}
                styled={{ color: lightThemeColors.background }}
              />
            </Link>
          </Line>
        </TextWrapper>

        <DecorationWrapper
          as={motion.div}
          initial={{ rotate: 0 }}
          animate={{ rotate: 360 }}
          transition={{
            duration: 20,
            repeat: Infinity,
            repeatType: "loop",
            type: "tween",
            ease: "linear",
          }}
        >
          <motion.div
            style={{
              position: "absolute",
              width: "100%",
            }}
          >
            <Circle5 />
          </motion.div>
          <motion.div
            style={{
              position: "absolute",
              width: "75%",
            }}
          >
            <Circle4 />
          </motion.div>
          <motion.div
            style={{
              position: "absolute",
              width: "60%",
            }}
          >
            <Circle3 />
          </motion.div>
          <motion.div
            style={{
              width: "100%",
              maxWidth: "55%",
            }}
          >
            <Circle2 />
          </motion.div>

          <motion.div
            style={{
              position: "absolute",
              width: "40%",
            }}
          >
            <Circle1 />
          </motion.div>
          <motion.div
            initial={{ rotate: 0 }}
            animate={{ rotate: -360 }}
            transition={{
              duration: 20,
              repeat: Infinity,
              repeatType: "loop",
              type: "tween",
              ease: "linear",
            }}
            style={{
              position: "absolute",
              top: "10%",
              width: "60px",
            }}
          >
            <AssetTop />
          </motion.div>
          <motion.div
            initial={{ rotate: 0 }}
            animate={{ rotate: -360 }}
            transition={{
              duration: 20,
              repeat: Infinity,
              repeatType: "loop",
              type: "tween",
              ease: "linear",
            }}
            style={{
              position: "absolute",
              bottom: "10%",
              width: "60px",
            }}
          >
            <AssetBottom />
          </motion.div>
          <motion.div
            initial={{ rotate: 0 }}
            animate={{ rotate: -360 }}
            transition={{
              duration: 20,
              repeat: Infinity,
              repeatType: "loop",
              type: "tween",
              ease: "linear",
            }}
            style={{
              position: "absolute",
              right: "10%",
              width: "60px",
            }}
          >
            <AssetRight />
          </motion.div>
          <motion.div
            initial={{ rotate: 0 }}
            animate={{ rotate: -360 }}
            transition={{
              duration: 20,
              repeat: Infinity,
              repeatType: "loop",
              type: "tween",
              ease: "linear",
            }}
            style={{
              position: "absolute",
              left: "10%",
              width: "60px",
            }}
          >
            <AssetLeft />
          </motion.div>
          <motion.div
            initial={{ rotate: 0 }}
            animate={{ rotate: -360 }}
            transition={{
              duration: 20,
              repeat: Infinity,
              repeatType: "loop",
              type: "tween",
              ease: "linear",
            }}
            style={{
              position: "absolute",
              width: "80px",
            }}
          >
            <AssetCenter />
          </motion.div>
        </DecorationWrapper>
      </Container>
    </FullPageContainer>
  );
};

export default Fold1;

const FullPageContainer = styled.div`
  display: flex;
  width: 100%;
  background: ${lightThemeColors.linkGeneratorBg};
  position: relative;
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 1512px;
  margin-left: auto;
  margin-right: auto;
  z-index: 1;
  background: ${lightThemeColors.linkGeneratorBg};
  padding: 100px 80px;
  gap: 40px;
  position: relative;
  overflow: hidden;
  @media (max-width: 990px) {
    flex-direction: column;
    padding: 20px;
  }
`;

const Title = styled.h1`
  color: ${lightThemeColors.primaryColor};
  font-size: 55px;
  font-weight: 700;
  line-height: 60px;
  text-align: left;
  z-index: 2;
  span {
    color: rgba(21, 49, 91, 0.2);
  }

  @media (max-width: 990px) {
    font-size: 35px;
    line-height: 40px;
  }
`;

const Subtitle = styled.h2`
  color: rgba(102, 100, 100, 1);
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  margin-top: 0px;
  max-width: 700px;
  z-index: 2;

  @media (max-width: 990px) {
    font-size: 16px;
    line-height: 20x;
  }
`;

const DecorationWrapper = styled.circle`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 450px;
  min-width: 450px;
  max-height: 450px;
  max-width: 450px;

  svg {
    display: flex;
    height: 100%;
    width: 100%;
  }
    @media(max-width:550px){
    display:none;
    }
`;
const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  z-index: 1;
`;
const Line = styled.div`
  display: flex;
  gap: 15px;
  align-items: center;
`;
