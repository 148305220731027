import { SERVICES_ENDPOINT } from "../../constants/constants";
import { GET } from "../API";
import { POST } from "../API";



export const getPostsByPage = (
    data,
    onSuccess,
    onError
) => {


    POST(
        SERVICES_ENDPOINT,
        `/blog/posts`,
        data,
        onSuccess,
        onError,
    );
};

export const getPostForId = (
    id,
    onSuccess,
    onError
) => {

    GET(SERVICES_ENDPOINT,
        `/blog/post_for_id/${id}`,
        onSuccess,
        onError,

    );
};

export const getLatestBlogPosts = (
    onSuccess,
    onError
) => {
    GET(SERVICES_ENDPOINT,
        `/blog/latest_posts`,
        onSuccess,
        onError,

    );
};

export const getRandomPostsForPost = (
    id,
    onSuccess,
    onError
) => {
    const data = { id: id };
    POST(SERVICES_ENDPOINT,
        `/blog/random_posts_for_post`,
        data,
        onSuccess,
        onError,

    );
};

export const searchPost = (
    page,
    term,
    onSuccess,
    onError,

) => {
    const data = {
        page: page,
        term: term,
    }
    POST(SERVICES_ENDPOINT,
        `/blog/search`,
        data,
        onSuccess,
        onError,

    );
};
