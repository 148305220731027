import React from "react";
import styled from "styled-components";
import { lightThemeColors } from "../../assets/style/colors";
import MoveFromLeftToRightAnimation from "../../Animations/MoveFromLeftToRight";

const UseCaselement = ({
  title,
  description,
  descriptionLabel,
  diff,
  index,
}) => {
  return (
    <Container index={index} diff={diff} >
      <Title index={index} diff={diff} >{title}</Title>
      <Description index={index} diff={diff} >
        <label>{descriptionLabel}</label>
        {description}
      </Description>
    </Container>

  );
};

export default UseCaselement;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width:50%;
  padding: 40px;
  border-radius:20px;
      
  background: ${(props) =>
    props.diff
      ? lightThemeColors.cardBgDark
      : "transparent"};

  @media (max-width: 990px) {
    background: ${(props) =>
    props.index % 2
      ? lightThemeColors.cardBgDark
      : "transparent"};
    max-width:100%;
    padding: 20px;
    gap: 10px;
  }

`;
const Title = styled.h1`
  font-size: 30px;
  font-weight: 600;
  line-height: 36px;
  text-align: left;


  color :${(props) =>
    props.diff
      ? lightThemeColors.background
      : lightThemeColors.darkColor
  };

  @media(max-width:990px){
  color :${(props) =>
    props.index % 2
      ? lightThemeColors.background
      : lightThemeColors.darkColor
  };
  }

`;
const Description = styled.p`
    color :${(props) =>
    props.diff
      ? lightThemeColors.cardTextColorDark
      : lightThemeColors.textColor
  };

  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;

  label {
    font-weight: 600;
       color :${(props) =>
    props.diff
      ? lightThemeColors.background
      : lightThemeColors.darkColor
  };

}

  @media (max-width: 990px) {
  color:${props => props.index % 2
    ? lightThemeColors.cardTextColorDark
    : lightThemeColors.textColor
  };

  label{
     color :${(props) =>
    props.index % 2
      ? lightThemeColors.background
      : lightThemeColors.darkColor
  };
  }
}
`;
