import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import DynamicLinkGeneratorcard from "../DynamicLinkGeneratorComponents/DynamicLinkGeneratorcard";
import AnimatedTextButton from "../generic/AnimatedTextButton";
import { lightThemeColors } from "../../assets/style/colors";
import InputFieldWithValidation from "../generic/InputFieldWithValidation";
import CustomTextArea from "../generic/CustomTextArea";
import PrimaryButton from "../generic/PrimaryButton";
import { color } from "framer-motion";
import { isEmail } from "validator";
import { useNavigate, useOutletContext } from "react-router-dom";
import {
  sendContactEmailAPICall,
  sendSalesEmailAPICall,
} from "../../API/Contact/contactService";
import {
  customSuccessNotification,
  showGenericError,
  showSuccessNotification,
} from "../../helpers/Notifications";
import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";

const ContactCard = ({ title, titleSpan, subtitle, type }) => {

  const recaptchaRef = useRef(null);
  const recaptchaKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY
  const [name, setName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [email, setEmail] = useState("");
  const [textAreaValue, setTextAreaValue] = useState("");
  const { setLoading } = useOutletContext()
  const inputContainerStyle = {
    border: "none",
  };
  const navigate = useNavigate();
  const isValid = () => {
    var valid = false;
    if (
      email.length > 3 &&
      isEmail(email) &&
      name.length > 3 &&
      companyName.length > 3 &&
      textAreaValue.length > 3
    ) {
      valid = true;
    } else {
      valid = false;
    }
    return valid;
  };

  const handleContactSupport = () => {
    navigate("/contact");
  };

  const handleGotoDocs = () => {
    window.open("https://docs.linksquared.io/s/docs");
  };

  const titleStyle =
  {
    fontSize: '16px',
  }



  const resetFields = () => {
    setName("");
    setEmail("");
    setCompanyName("");
    setTextAreaValue("");
  };

  const recaptchaVerify = async (e) => {
    e.preventDefault();
    const recaptchaValue = await recaptchaRef.current.executeAsync();
    recaptchaRef.current.reset();
    if (recaptchaValue) {
      handleSubmit(e)
    }
  }

  const handleContactSales = (email, name, company, description) => {

    setLoading(true)
    sendSalesEmailAPICall(
      email,
      name,
      company,
      description,
      (response) => {
        customSuccessNotification("Your message was sent, we’ll get back to you in 24h, thanks for reaching out!")
        resetFields();
        setLoading(false)
      },
      (error) => {
        showGenericError();
        setLoading(false)
      }
    );
  };
  const handleContactSupportTeam = (email, name, company, description) => {
    setLoading(true)
    sendContactEmailAPICall(
      email,
      name,
      company,
      description,
      (response) => {
        customSuccessNotification("Your message was sent, we’ll get back to you in 24h, thanks for reaching out!")
        resetFields();
        setLoading(false)
      },
      (error) => {
        showGenericError();
        setLoading(false)
      }
    );
  };

  const handleSubmit = (e) => {
    switch (type) {
      case "sales":

        handleContactSales(email, name, companyName, textAreaValue);
        break;
      case "support":
        handleContactSupportTeam(email, name, companyName, textAreaValue);
        break;

      default:
        break;
    }
  };

  return (
    <DynamicLinkGeneratorcard>

      <Content>

        <LeftSide>
          <ReCAPTCHA
            hidden={true}
            ref={recaptchaRef}
            size="invisible"
            sitekey={recaptchaKey}
          />
          <TitleWrapper>
            <Title>
              {title} <br />
              <span>{titleSpan}</span>
            </Title>
            <Subtitle>{subtitle}</Subtitle>
          </TitleWrapper>
          <InfoWrapper>
            <Info>Looking for technical support?</Info>
            <Line>
              <AnimatedTextButton
                onClick={handleGotoDocs}
                style={{
                  borderBottom: `1px solid ${lightThemeColors.darkColor}`,
                }}
                text={"Check the documentation"}
              />
              {type === "sales" && (
                <AnimatedTextButton
                  onClick={handleContactSupport}
                  style={{
                    borderBottom: `1px solid ${lightThemeColors.darkColor}`,
                  }}
                  text={"Contact support"}
                />
              )}
            </Line>
          </InfoWrapper>
        </LeftSide>
        <RightSide>
          <InputFieldWithValidation
            title={"Name *"}
            noValidation
            placeholder={"First and last name"}
            inputContainerStyle={inputContainerStyle}
            inputValue={name}
            handleInputChange={(e) => setName(e.currentTarget.value)}
            titleStyle={titleStyle}

          />
          <InputFieldWithValidation
            title={"Work email *"}
            noValidation
            placeholder={"name@example.com"}
            inputContainerStyle={inputContainerStyle}
            inputValue={email}
            handleInputChange={(e) => setEmail(e.currentTarget.value)}
            titleStyle={titleStyle}

          />
          <InputFieldWithValidation
            title={"Copany name *"}
            noValidation
            placeholder={"Example, Inc."}
            inputContainerStyle={inputContainerStyle}
            inputValue={companyName}
            handleInputChange={(e) => setCompanyName(e.currentTarget.value)}
            titleStyle={titleStyle}
          />
          <CustomTextArea
            title={"How can we help? *"}
            placeholder={"How can we help?"}
            textAreaStyle={{ border: "none" }}
            value={textAreaValue}
            onChange={(e) => setTextAreaValue(e.currentTarget.value)}
          />
          <PrimaryButton
            text={"Submit"}
            styled={{
              width: "100%",
              padding: "10px",
              color: lightThemeColors.background,
            }}
            bgVariant={lightThemeColors.darkColor}
            onClick={(e) => recaptchaVerify(e)}
            disabled={!isValid()}
          />
        </RightSide>
      </Content>
    </DynamicLinkGeneratorcard>
  );
};

export default ContactCard;

const Content = styled.div`
  display: flex;
  width: 100%;
  gap: 50px;
  @media (max-width: 990px) {
    flex-direction: column;
    gap: 30px;
  }
`;
const LeftSide = styled.div`
  display: flex;
  flex-direction: column;
  gap: 80px;
  width: 100%;
  @media (max-width: 990px) {
    gap: 30px;
  }
`;
const RightSide = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  width: 100%;
  @media (max-width: 990px) {
    gap: 30px;
  }
`;
const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  @media (max-width: 990px) {
    gap: 15px;
  }
`;
const Title = styled.h1`
  font-size: 50px;
  font-weight: 700;
  line-height: 70px;
  color: ${lightThemeColors.darkColor};
  span {
    color: ${lightThemeColors.primaryColor};
  }

  @media (max-width: 990px) {
    font-size: 30px;
    line-height: 36px;
    br {
      display: none;
    }
  }
`;
const Subtitle = styled.p`
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: rgba(48, 44, 44, 0.7);

  @media (max-width: 990px) {
    font-size: 16px;
    line-height: 20px;
  }
`;
const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  @media (max-width: 990px) {
    gap: 15px;
  }
`;
const Line = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;
`;
const Info = styled.p`
  font-size: 24px;
  font-weight: 700;
  line-height: 30px;
  text-align: left;
  color: ${lightThemeColors.darkColor};
  @media (max-width: 990px) {
    font-size: 24px;
    line-height: 26px;
  }
`;
