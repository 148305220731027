import React, { useRef, useState } from "react";

import Slider from "react-slick";
import CarouselItem from "./CarouselItem";
import styled from "styled-components";
import { ReactComponent as Arrow } from "../../assets/icons/arrowRightIcon.svg";
import { lightThemeColors } from "../../assets/style/colors";
const Carousel = () => {
  const ref = useRef();
  const [slideIndex, setSlideIndex] = useState(1);

  const slides = [
    {
      comment:
        "We were in search of an alternative to Branch and discovered linksquared. The switch was simple and straightforward, thanks to their top-notch documentation, platform and support. 100% would recommend!",
      author: "Mike Deeks, Senior iOS Engineer",
    },
    {
      comment:
        "Switching to LinkSquared has cut our costs by over 50% while maintaining the same performance and providing more metrics.",
      author: "Rhonda McKinsay, Product Manager",
    },
    {
      comment:
        "In our search for a solution to enhance our marketing efforts—tracking traffic sources and identifying effective marketing campaigns that drive app installs — we found linksquared to be straightforward, affordable, and highly effective. We're delighted with the results it has delivered.",
      author: "Darrell Grahm, CEO",
    },
    {
      comment:
        "We needed a way to add a referral system to our mobile apps. Thanks to linksquared, we got it done in less than a day—quick, easy, and developer-friendly.",
      author: "Andrei Petrescu, CTO",
    },
  ];

  var settings = {
    dots: false,
    arrows: false,
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "60px",
    slidesToShow: 2,
    initialSlide: 1,

    beforeChange: (current, next) => {
      setSlideIndex(next);
    },

    responsive: [
      {
        breakpoint: 768,
        settings: {
          centerPadding: "0px",
          centerMode: true,
          infinite: true,
          slidesToShow: 1,
          initialSlide: 1,
        },
      },
    ],
  };

  return (
    <Container>
      <Slider {...settings} ref={ref}>
        {slides.map((slide, index) => (
          <CarouselItem
            key={index}
            comment={slide.comment}
            author={slide.author}
          />
        ))}
      </Slider>

      <NavigationBtnWrapper>
        <CustomArrowBtn onClick={() => ref.current.slickPrev()}>
          <Arrow style={{ rotate: "180deg" }} />
        </CustomArrowBtn>
        <CustomArrowBtn onClick={() => ref.current.slickNext()}>
          <Arrow />
        </CustomArrowBtn>
      </NavigationBtnWrapper>
    </Container>
  );
};

export default Carousel;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
`;
const NavigationBtnWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  padding-left: 70px;
  @media (max-width: 990px) {
    padding-left: 20px;
  }
`;
const CustomArrowBtn = styled.button`
  background: none;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  padding: 10px 10px;
  background: ${lightThemeColors.darkBannerBg};
  color: ${lightThemeColors.background};
  cursor: pointer;
  border: none;
  svg {
    height: auto;
    width: 16px;
  }

  &:disabled {
    opacity: 0.3;
    background: ${lightThemeColors.background};
    color: ${lightThemeColors.textColor};
    cursor: inherit;
  }
`;
