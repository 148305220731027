import React from 'react'

import { PuffLoader } from 'react-spinners'
import styled from 'styled-components'
import { lightThemeColors } from '../../assets/style/colors'



const LoaderComponent = ({ loading, children }) => {


    return (
        <Container>

            {loading &&
                <LoaderContainer>
                    <PuffLoader
                        size={100}
                        color={lightThemeColors.darkColor}
                        loading={loading}
                    />
                </LoaderContainer>}

            {children}
        </Container>
    )
}

export default LoaderComponent
const Container = styled.div`
position:relative;
height:100%;
display:flex;
flex-direction:column;
overflow:hidden;
`
const LoaderContainer = styled.div`
position:absolute;
width:100%;
height:100%;
display:flex;
align-items:center;
justify-content:center;
z-index:10;
`