import React, { useRef } from 'react'
import { motion, useInView, } from "framer-motion";

const MoveFromLeftToRightAnimation = ({ children }) => {
    const ref = useRef();
    const inView = useInView(ref, { once: true })

    return (
        <motion.div
            ref={ref}
            style={{
                transform: inView ? "none" : "translateX(-100%)",
                transition: "all linear 0.4s "
            }}
        >
            {children}
        </motion.div>

    )
}

export default MoveFromLeftToRightAnimation
