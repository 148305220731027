import React from 'react'
import styled from 'styled-components'
import InputFieldWithValidation from '../generic/InputFieldWithValidation'
import { ReactComponent as AndroidIcon } from "../../assets/icons/android.svg"
import { isURL } from 'validator'

const AndroidConfig = ({ tablets, phone, setPhone, tablet, setTablet }) => {

    return (
        <InputWrapper>
            <InputFieldWithValidation
                subtitle={tablets ? "Phone" : ""}
                titleIcon={<AndroidIcon />}
                placeholder={"Enter the link to redirect to on Android devices"}
                title={"Android"}
                label={"The dynamic link will open this page if viewed from an Android device"}
                bottomLine={!tablets}
                inputValue={phone}
                valid={isURL(phone)}
                handleInputChange={(e) => setPhone(e.currentTarget.value)}
            />
            {tablets &&
                <InputFieldWithValidation
                    subtitle={"Tablet"}
                    placeholder={"Enter the link to redirect to on Android tablet"}
                    label={"The dynamic link will open this page if viewed from an Android tablet device"}
                    bottomLine
                    inputValue={tablet}
                    valid={isURL(tablet)}
                    handleInputChange={(e) => setTablet(e.currentTarget.value)}
                />

            }

        </InputWrapper>
    )
}

export default AndroidConfig

const InputWrapper = styled.div`
display:flex;
gap:17px;
flex-direction:column;
width:100%;
`