import React from "react";
import styled from "styled-components";
import UseCaselement from "./UseCaselement";

const UseCases = () => {
  const useCasesList = [
    {
      diff: 0,
      title: "E-commerce Optimization",
      descriptionLabel: "Mobile vs. Desktop:",
      description:
        " A fashion retailer directs mobile users to a streamlined shopping experience designed for quick browsing and purchasing, while desktop users get a full catalog with detailed descriptions and reviews.",
    },
    {
      diff: 1,
      title: "Media Consumption",
      descriptionLabel: "Streaming Redirects:",
      description:
        " A video streaming service directs mobile users to download their app or to a mobile-optimized web player, while desktop users are sent to the full website for an enhanced viewing experience.",
    },
    {
      diff: 1,
      title: "Targeted Marketing Campaigns",
      descriptionLabel: "Device-Specific Promotions:",
      description:
        " A marketing agency running a campaign for a new product might direct mobile users to a quick sign-up page with a special offer and desktop users to an interactive product demo.",
    },
    {
      diff: 0,
      title: "Education and e-Learning",
      descriptionLabel: "Course Access: ",
      description:
        " An online education platform sends mobile users to a mobile app download page where they can easily continue their coursework, while desktop users are directed to a portal with comprehensive resources and tools for in-depth study.",
    },
    {
      diff: 0,
      title: "Event Ticketing",
      descriptionLabel: "Simplified Mobile Booking:",
      description:
        "  A concert promoter directs mobile users to a mobile-friendly ticket purchasing site to ensure a quick and easy checkout process, while desktop users get access to detailed event information and seating charts.",
    },
    {
      diff: 1,
      title: "Travel Bookings",
      descriptionLabel: "User-Friendly Experience:",
      description:
        " A hotel booking site directs mobile users to a simplified booking form with large buttons and easy navigation, while desktop users can access more detailed search filters and hotel information.",
    },
  ];
  return (
    <Container id="useCaseContainer">
      <h1>Use cases</h1>
      <UseCaseList>
        {useCasesList.map((item, index) => (
          <UseCaselement
            key={index}
            index={index}
            diff={item.diff}
            title={item.title}
            descriptionLabel={item.descriptionLabel}
            description={item.description}
          />
        ))}
      </UseCaseList>
    </Container>
  );
};

export default UseCases;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  scroll-margin-top: 60px;
`;
const UseCaseList = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  row-gap: 60px;
  border-radius: 20px;
  overflow: hidden;
  padding: 60px 0;
  background: rgba(246, 244, 242, 0.59);
  
  @media (max-width: 990px) {
    row-gap: 20px;
    padding: 30px 0;
  }
`;
