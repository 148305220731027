import React, { useEffect } from "react";
import styled from "styled-components";
import { lightThemeColors } from "../../assets/style/colors";
import PrimaryButton from "../generic/PrimaryButton";
import {
  createSearchParams,
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { ReactComponent as Logo } from "../../assets/icons/LogoLarge.svg";

import { useExpanded } from "../../hooks/useExpanded";
import { ReactComponent as MenuIcon } from "../../assets/icons/menuIcon.svg";

import SmallScreenMenuItem from "./SmallScreenMenuItem";
import { animate, AnimatePresence, motion } from "framer-motion";


const Header = ({ fold1Ref }) => {

  const [expanded, expand] = useExpanded();

  const navigate = useNavigate();
  const location = useLocation()
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const element = document.getElementById("headerId");

    if (location.pathname != '/') {
      animate(element, { background: lightThemeColors.background })
    }

  }, [location.pathname])

  const menuBtns = [
    {
      text: "Product",
      link: "product",
      type: "scroll",
    },
    {
      text: "Solutions",
      link: "benefits",
      type: "scroll",
    },
    {
      text: "Blog",
      link: "/blog",
      type: "normal",
    },
    {
      text: "Documentation",
      link: "https://docs.linksquared.io/s/docs",
      type: "external",
    },
    {
      text: "Pricing",
      link: "/pricing",
      type: "normal",
    },
    {
      text: "Generator",
      link: "/create",
      type: "normal",
    },
  ];

  const navigateTo = (to, type) => {
    switch (type) {
      case "normal":
        navigate(to);
        break;
      case "external":
        window.open(to, "_blank");
        break;
      case "scroll":
        let element = document.getElementById(to);
        if (element) {
          element?.scrollIntoView({ behavior: "smooth" });
        } else {
          setSearchParams((params) => {
            params.append("scrollTo", to);
          });
          navigate({
            pathname: "/",
            search: createSearchParams(searchParams).toString(),
          });
        }

        break;

      default:
        break;
    }
  };

  const handleSmallMenuItemClick = (link, type) => {
    navigateTo(link, type);
    // if (external) {
    //   window.open(link, "_blank");
    // } else {
    //   navigateTo(link, type);
    // }
    expand();
  };

  return (
    <FullPageContainer
      as={motion.div}
      id="headerId"
    >
      <OuterWrapper>
        <Container>
          <Link to={"/"}>
            <TitleWrapper>
              <LogoWrapper>
                <Logo />
              </LogoWrapper>
            </TitleWrapper>
          </Link>

          <ButtonsWrapper>
            {menuBtns.map((item, index) => (
              <HeaderBtn
                key={index}
                onClick={() => navigateTo(item.link, item.type)}
              >
                {item.text}
              </HeaderBtn>
            ))}
          </ButtonsWrapper>
          <ButtonsWrapper style={{ marginLeft: "auto" }}>
            <HeaderBtn
              style={{ color: `${lightThemeColors.darkColor}` }}
              onClick={() =>
                navigateTo("http://app.linksquared.io/login", "external")
              }
            >
              Log in
            </HeaderBtn>

            <PrimaryButton
              styled={{
                padding: "10px 20px",
                color: lightThemeColors.background,
                background: lightThemeColors.darkColor,
              }}
              onClick={() =>
                navigateTo("http://app.linksquared.io/register", "external")
              }
              text={"Get started"}
            />
          </ButtonsWrapper>

          <SmallScreenMenu>
            <PrimaryButton
              onClick={expand}
              styled={{
                color: lightThemeColors.background,
                padding: "10px",
                minWidth: "50px",
              }}
              icon={<MenuIcon />}
              bgVariant={lightThemeColors.darkColor}
            />
          </SmallScreenMenu>
        </Container>
      </OuterWrapper>

      <AnimatePresence>
        {expanded && (
          <>
            <MenuContentWrapper
              initial={{ height: 0 }}
              animate={{ height: "100%" }}
              exit={{ height: 0 }}
              transition={{ duration: 0.4 }}
            >
              <Container>
                <TitleWrapper onClick={() => handleSmallMenuItemClick("/", "normal")}>
                  <LogoWrapper>
                    <Logo />
                  </LogoWrapper>
                </TitleWrapper>
                <ButtonsWrapper>
                  {menuBtns.map((item, index) => (
                    <HeaderBtn
                      key={index}
                      onClick={() => handleSmallMenuItemClick(item.link, item.type)}
                    >
                      {item.text}
                    </HeaderBtn>
                  ))}
                </ButtonsWrapper>
                <ButtonsWrapper style={{ marginLeft: "auto" }}>
                  <HeaderBtn
                    style={{ color: `${lightThemeColors.darkColor}` }}
                    onClick={() =>
                      handleSmallMenuItemClick("http://app.linksquared.io/login", "external")
                    }
                  >
                    Log in
                  </HeaderBtn>

                  <PrimaryButton
                    styled={{
                      padding: "10px 20px",
                      color: lightThemeColors.background,
                      background: lightThemeColors.darkColor,
                    }}
                    onClick={() =>
                      handleSmallMenuItemClick(
                        "http://app.linksquared.io/register",
                        "external"
                      )
                    }
                    text={"Get started"}
                  />
                </ButtonsWrapper>

                <SmallScreenMenu>
                  <PrimaryButton
                    onClick={expand}
                    styled={{
                      color: lightThemeColors.background,
                      padding: "10px",
                      minWidth: "50px",
                    }}
                    icon={<MenuIcon />}
                    bgVariant={lightThemeColors.darkColor}
                  />
                </SmallScreenMenu>
              </Container>

              {menuBtns.map((item, index) => (
                <SmallScreenMenuItem
                  key={index}
                  text={item.text}
                  onClick={() =>
                    handleSmallMenuItemClick(item.link, item.type)
                  }
                />
              ))}
            </MenuContentWrapper>
          </>
        )}
      </AnimatePresence>
    </FullPageContainer>
  );
};

export default Header;

const FullPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 10;
  background: ${lightThemeColors.background};
  border-bottom: 1px solid #f6f4f2;
  min-height: 61px;
  justify-content: center;
`;
const OuterWrapper = styled.div`
  display: flex;
  width: 100%;
  max-width: 1512px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 40px;
  position: relative;
  @media (max-width: 990px) {
    padding: 0 20px;
  }
`;
const Container = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 0;
  width: 100%;
  max-width: 1512px;
  margin-left: auto;
  margin-right: auto;
  gap: 50px;
`;
const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  @media (max-width: 990px) {
    display: none;
  }
`;
const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  p {
    color: ${lightThemeColors.darkColor};
    font-size: 20px;
    font-weight: 800;
    line-height: 28px;
    text-align: left;
  }
`;
const LeftLine = styled.div`
  display: flex;
  height: 30px;
  width: 5px;
  background: ${lightThemeColors.primaryColor};
`;

const HeaderBtn = styled.button`
  display: flex;
  align-items: center;
  background: none;
  border: none;
  cursor: pointer;

  color: ${lightThemeColors.darkColor};
  font-size: 14px;
  font-weight: 500;
  line-height: 16.8px;
  text-align: left;
`;
const LogoWrapper = styled.div`
  svg {
    max-width: 120px;
  }
`;

const MenuContentWrapper = styled(motion.div)`
  display: flex;
  flex-direction: column;
  height: 100%;
  background: ${lightThemeColors.background};
  position: fixed;
  top: 0px;
  left: 0;
  width: 100%;
  padding: 0px 20px;
  overflow: hidden;
`;

const SmallScreenMenu = styled.div`
  display: none;
  margin-left: auto;
  @media (max-width: 990px) {
    display: flex;
  }
`;
