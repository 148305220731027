import React from "react";
import styled from "styled-components";
import { ReactComponent as ValidIcon } from "../../assets/icons/validCircle.svg";
import { lightThemeColors } from "../../assets/style/colors";
const InputFieldWithValidation = ({
  titleIcon,
  title,
  subtitle,
  label,
  inputValue,
  labelAction,
  handleInputChange,
  inputName,
  valid,
  style,
  placeholder,
  noValidation,
  type,
  readOnly,
  bottomLine,
  inputStyle,
  inputContainerStyle,
  titleStyle,
}) => {
  return (
    <Container style={style}>
      {title && (
        <TitleWrapper >
          {titleIcon && titleIcon}
          <h1 style={titleStyle}>{title}</h1>
        </TitleWrapper>
      )}
      {subtitle && <h2>{subtitle}</h2>}
      <InputContainer readOnly={readOnly} style={inputContainerStyle}>
        <input
          style={inputStyle}
          readOnly={readOnly}
          value={inputValue}
          onChange={handleInputChange}
          name={inputName}
          placeholder={placeholder}
          type={type}
        />
        {!noValidation ? (
          valid ? (
            <ValidIcon style={{ color: lightThemeColors.validGreen }} />
          ) : (
            <ValidIcon style={{ color: lightThemeColors.darkColorFullFaded }} />
          )
        ) : (
          ""
        )}
      </InputContainer>
      {label && <label onClick={labelAction}>{label}</label>}
      {bottomLine && <BottomLine />}
    </Container>
  );
};

export default InputFieldWithValidation;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;

  h2 {
    color: ${lightThemeColors.darkColor};
    font-size: 12px;
    font-weight: 600;
    line-height: 20px;
  }
  label {
    cursor: pointer;
    color: ${lightThemeColors.inputFieldLabelColor};
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    text-align: left;
  }
`;

const InputContainer = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  padding: 7px 20px;
  border-radius: 5px;
  border: 1px solid ${lightThemeColors.inputFieldBorder};
  background: ${lightThemeColors.background};
  gap: 10px;

  input {
    text-overflow: ellipsis;
    width: 100%;
    outline: none;
    border: none;
    color: ${lightThemeColors.darkColor};
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
  }

  :: placeholder {
    opacity: 0.3;
  }

  svg {
    max-height: 20px;
    max-width: 20px;
  }
`;
const TitleWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  h1 {
    color: ${lightThemeColors.darkColor};
    font-size: 18px;
    font-weight: 600;
    line-height: 20px;
  }

  svg {
    max-height: 16px;
    max-width: 20px;
  }
`;

const MarginLeft20 = styled.div`
  margin-left: 20px;
`;

const BottomLine = styled.div`
  width: 100%;
  min-height: 1px;
  background: rgba(120, 118, 118, 0.06);
  margin-top: 20px;
  @media (ma-width: 990px) {
    margin-top: 0;
  }
`;
