import styled from "styled-components";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import { Outlet } from "react-router-dom";
import LoaderComponent from "./components/generic/LoaderComponent";
import { useEffect, useRef, useState } from "react";
import BlueHeader from "./components/Header/BlueHeader";
import CookieConsentElement from "./helpers/CookieConsent";
import ScrollToTop from "./helpers/ScrollToTop";



function App() {

  const [loading, setLoading] = useState(false);


  const scrollRef = useRef(null);
  const fold1Ref = useRef(null);

  ScrollToTop();



  return (
    <LoaderComponent loading={loading}>
      <Container id='scroller' ref={scrollRef}>
        <BlueHeader />
        <Header
          fold1Ref={fold1Ref}
        />
        <Outlet
          context={{
            loading, setLoading,
            scrollRef, fold1Ref,

          }} />
        <Footer />
      </Container>
      <CookieConsentElement />
    </LoaderComponent>

  )
}

export default App;

const Container = styled.div`
display:flex;
flex-direction:column;
height:100%;
width:100%;
overflow:auto;
position:relative;
`
