import { SERVICES_ENDPOINT, SQD_ENDPOINT } from "../../constants/constants";
import { POST } from "../API";

export const sendContactEmailAPICall = (
    name, email, company, description,
    onSuccess,
    onError
) => {
    const data = {
        name: name,
        email: email,
        company: company,
        description: description,
    }
    POST(
        SERVICES_ENDPOINT,
        `/actions/send_contact_email`,
        data,
        onSuccess,
        onError
    );
};

export const sendSalesEmailAPICall = (
    name, email, company, description,
    onSuccess,
    onError
) => {
    const data = {
        name: name,
        email: email,
        company: company,
        description: description,
    }
    POST(
        SERVICES_ENDPOINT,
        `/actions/send_sales_email`,
        data,
        onSuccess,
        onError
    );
};
