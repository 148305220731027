import React from "react";
import styled from "styled-components";
import { lightThemeColors } from "../../assets/style/colors";

const CarouselItem = ({ comment, author }) => {
  return (
    <Container>
      <Comment>{comment}</Comment>
      <Author>{author}</Author>
    </Container>
  );
};

export default CarouselItem;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
  padding: 40px 60px;
  border-radius: 10px;
  background: ${lightThemeColors.background};
  margin: 0 20px;
  @media (max-width: 990px) {
    padding: 20px 30px;
  }
  @media (max-width: 550px) {
    margin: 0 10px;
  }
  overflow: hidden;
`;

const Comment = styled.h1`
  color: ${lightThemeColors.darkColor};
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  text-overflow: ellipsis;
  overflow: hidden;
  @media (max-width: 990px) {
    font-size: 18px;
    line-height: 20x;
  }
`;
const Author = styled.p`
  color: rgba(37, 34, 34, 0.6);
  font-size: 16px;
  font-weight: 500;
  line-height: 19apx;

  @media (max-width: 990px) {
    font-size: 14px;
    line-height: 20px;
  }
`;
