import React from "react";
import styled from "styled-components";
import { ReactComponent as Arrow } from "../../assets/icons/forward-arrow-small.svg";
import "../../assets/style/style.css";
import { lightThemeColors } from "../../assets/style/colors";

const AnimatedTextButton = ({
  onClick,
  text,
  style,
  className,
  light,
  disabled,
  reverseArrow,
  textColor,
}) => {
  var classNameValue = className + " slide-out-right";
  var arrow = (
    <AnimatedCell>
      <Arrow />
    </AnimatedCell>
  );

  let buttonContent = (
    <>
      <p style={{ color: textColor }}>{text}</p>
      {arrow}
    </>
  )

  if (reverseArrow) {
    arrow = (
      <AnimatedCell>
        <Arrow style={{ rotate: "180deg" }} />
      </AnimatedCell>
    )
    buttonContent = (
      <>
        {arrow}
        <p style={{ color: textColor }}>{text}</p>
      </>

    )
  }


  var clickAction = () => {
    if (onClick) {
      onClick();
    } else
      return

  }


  if (disabled) {
    classNameValue = classNameValue + " disabled";
    arrow = null;
    clickAction = () => { };
  }



  return (
    <ButtonWrapper
      style={style}
      className={classNameValue}
      light={light}
      onClick={clickAction}
    >
      {buttonContent}
    </ButtonWrapper>
  );
};

export default AnimatedTextButton;
const ButtonWrapper = styled.div`
display:flex;
column-gap:8px;
align-items:center;
cursor:pointer;
width:fit-content;
@media(max-width:400px){
  column-gap:2px;
 }

p{
    color:${(props) =>
    props?.light === "light" ? "white" : lightThemeColors.darkColor};
    margin:0;
    font-size: 16px;
padding:4px 0;
}
svg{

    stroke:${(props) =>
    props?.light === "light" ? "white" : lightThemeColors.darkColor};
    
}
}
.disabled {
    opacity: 0.3;
}

`;
const AnimatedCell = styled.div`
display:flex;
`;
