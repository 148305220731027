import React from "react";
import styled from "styled-components";
import { lightThemeColors } from "../../assets/style/colors";

const DynamicLinkGeneratorcard = ({ children, id }) => {
  return <Card id={id}>{children}</Card>;
};

export default DynamicLinkGeneratorcard;

const Card = styled.div`
  display: flex;
  gap: 50px;
  width: 100%;
  border-radius: 20px;
  background: ${lightThemeColors.linkGeneratorBg};
  padding: 60px 80px;
  scroll-margin-top: 30px;
  @media(max-width:990px){
  padding: 20px;
  }
`;
