import { useEffect } from "react";
import { useLocation, useSearchParams } from "react-router-dom";


const ScrollToTop = () => {
    const { pathname } = useLocation();
    const [searchParmas] = useSearchParams()
    const element = document.getElementById("scroller")

    useEffect(() => {
        if (!searchParmas.get("scrollTo")) {
            element?.scrollTo(0, 0)
        }
    }, [pathname]);
    return null
};

export default ScrollToTop;
