import React, { useContext, useEffect, useRef, useState } from "react";
import { lightThemeColors } from "../assets/style/colors";
import styled from "styled-components";
import { ReactComponent as CheckIcon } from "../assets/icons/GreenCheck.svg";
import PrimaryButton from "../components/generic/PrimaryButton";
import DynamicLinkGeneratorcard from "../components/DynamicLinkGeneratorComponents/DynamicLinkGeneratorcard";
import ConfigureRedirects from "../components/DynamicLinkGeneratorComponents/ConfigureRedirects";
import { isURL } from "validator";
import ConfigureSocialMediaPreview from "../components/DynamicLinkGeneratorComponents/ConfigureSocialMediaPreview";
import { FILE, LINK } from "../constants/constants";
import GeneratedLink from "../components/DynamicLinkGeneratorComponents/GeneratedLink";
import UseCases from "../components/GenerateLinkComponents/UseCases";
import { AnimatePresence, } from "framer-motion";
import { useOutletContext } from "react-router-dom";
import { createFreeLinkAPICall } from "../API/DynamicLink/dynamicLinkService";
import MoveFromLeftToRightAnimation from "../Animations/MoveFromLeftToRight";
import ReCAPTCHA from "react-google-recaptcha";
const RedirectItem = ({ text }) => {
  return (
    <RedirectItemWrapper>
      <CheckIcon />
      <p>{text}</p>
    </RedirectItemWrapper>
  );
};
const DynamicLinkGenerator = () => {
  const REDIRECTS_LIST = [
    "iOS",
    "Android",
    "Web",
    "Phone",
    "Tablets",
    "MacOS",
    "Windows",
    "Linux",
  ];
  const recaptchaRef = useRef(null);
  const recaptchaKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY

  const { setLoading } = useOutletContext();
  const [phase, setPhase] = useState(1);

  const [nonMobileDevices, setNonMobileDevices] = useState(false);
  const [tablets, setTablets] = useState(false);

  const [iosPhone, setIosPhone] = useState("");
  const [androidPhone, setAndroidPhone] = useState("");

  const [iosTablet, setIosTablet] = useState("");
  const [androidTablet, setAndroidTablet] = useState("");

  const [desktop, setDesktop] = useState("");
  const [mac, setMac] = useState("");
  const [linux, setLinux] = useState("");
  const [validInputs, setvalidInputs] = useState(false);

  const [smTitle, setSmTitle] = useState("");
  const [smSubtitle, setSmSubtitle] = useState("");
  const [smFile, setSmFile] = useState(null);
  const [smLink, setSmLink] = useState("");
  const [smPictureType, setSmPictureType] = useState(FILE);

  const [generatedLink, setGeneratedLink] = useState("");
  const [qrCode, setQrCode] = useState(null);

  const handleContinue = () => {
    setPhase(2);
  };

  const handleBackBtn = () => {
    setPhase(1);
  };



  const handleGenerateDynamicLink = () => {
    setLoading(true)

    const formData = new FormData();
    formData.append("ios_phone", iosPhone)
    formData.append("android_phone", androidPhone)

    if (tablets) {
      formData.append("ios_tablet", iosTablet)
      formData.append("android_tablet", androidTablet)
    }

    if (nonMobileDevices) {
      formData.append("desktop_windows", desktop)
      formData.append("desktop_mac", mac)
      formData.append("desktop_linux", linux)
    }

    else {
      formData.append("desktop", desktop)
    }

    if (smTitle) {
      formData.append("title", smTitle)
    }

    if (smSubtitle) {
      formData.append("subtitle", smSubtitle)
    }

    if (smPictureType === FILE && smFile) {
      formData.append("image", smFile[0])
    }

    if (smPictureType === LINK && smLink != "") {
      formData.append("image_url", smLink)
    }

    createFreeLinkAPICall(
      formData,
      (response) => {

        setPhase(3);
        setLoading(false);
        setGeneratedLink(response.data.link.access_path)
      },
      (error) => {
        alert(error)
      }
    )
  };

  const recaptchVerify = async (e) => {
    e.preventDefault();
    const recaptchaValue = await recaptchaRef.current.executeAsync();
    recaptchaRef.current.reset();
    if (recaptchaValue) {
      handleGenerateDynamicLink(e)
    }

  }

  const handleRegisterFreeAccount = () => {
    alert("handleRegisterFreeAccount");
  };

  const handleGEnerateNewDynamicLink = () => {
    setNonMobileDevices(false);
    setTablets(false);
    setIosPhone("");
    setAndroidPhone("");
    setIosTablet("");
    setAndroidTablet("");
    setDesktop("");
    setMac("");
    setLinux("");
    setSmFile(null);
    setSmLink("");
    setSmPictureType(FILE);
    setSmTitle("");
    setSmSubtitle("");
    setPhase(1);
  };

  const verifyInputs = () => {
    var valid = false;
    const principalInputsValid = isURL(iosPhone) && isURL(androidPhone) && isURL(desktop);
    const tabletsInputvalid = isURL(androidTablet) && isURL(iosTablet);
    const desktopsValid = isURL(mac) && isURL(linux);

    if (tablets && nonMobileDevices) {
      valid = principalInputsValid && desktopsValid && tabletsInputvalid;
    } else if (nonMobileDevices) {
      valid = principalInputsValid && desktopsValid;
    } else if (tablets) {
      valid = tabletsInputvalid && principalInputsValid;
    } else {
      valid = principalInputsValid;
    }
    setvalidInputs(valid);
  };

  const scrollToDynamicLinkCard = () => {
    let element = document.getElementById("dynamicLinkCard")
    element.scrollIntoView({ behavior: 'smooth', });
  }
  const scrollToUseCase = () => {
    let element = document.getElementById("useCaseContainer");

    element.scrollIntoView({ behavior: 'smooth' });
  }

  useEffect(() => {
    verifyInputs();
  }, [
    tablets,
    nonMobileDevices,
    iosPhone,
    androidPhone,
    iosTablet,
    androidTablet,
    desktop,
    mac,
    linux,
  ]);

  return (
    <Container>
      <DescriptionWrapper>
        <ReCAPTCHA
          hidden={true}
          ref={recaptchaRef}
          size="invisible"
          sitekey={recaptchaKey}
        />
        <TitleSection>
          <MoveFromLeftToRightAnimation>
            <h1>
              Free <span>Dynamic Link </span> <br />
              Generator
            </h1>
          </MoveFromLeftToRightAnimation>

          <MoveFromLeftToRightAnimation>
            <ColumnGroup>
              <RedirectLabel>REDIRECTS ON</RedirectLabel>
              <RedirctsList>
                {REDIRECTS_LIST.map((item, index) => (
                  <RedirectItem key={index} text={item} />
                ))}
              </RedirctsList>
            </ColumnGroup>
          </MoveFromLeftToRightAnimation>

        </TitleSection>

        <DescriptionSection>
          <p>
            Create a free dynamic link that redirects users to your desired
            content based on their device type.
          </p>
          <Line>
            <PrimaryButton
              styled={{
                background: lightThemeColors.linkGeneratorBg,
                color: lightThemeColors.darkColor,
              }}
              onClick={() => scrollToUseCase()}
              text={"See use cases"}
            />
            <PrimaryButton
              styled={{
                background: lightThemeColors.darkColor,
                color: lightThemeColors.background,
              }}
              onClick={() => scrollToDynamicLinkCard()}
              text={"Generate dynamic link"}
            />
          </Line>
        </DescriptionSection>
      </DescriptionWrapper>

      <DynamicLinkGeneratorcard id={"dynamicLinkCard"}>
        <AnimatePresence>
          {phase === 1 && (
            <ConfigureRedirects
              nonMobileDevices={nonMobileDevices}
              setNonMobileDevices={setNonMobileDevices}
              tablets={tablets}
              setTablets={setTablets}
              iosPhone={iosPhone}
              setIosPhone={setIosPhone}
              androidPhone={androidPhone}
              setAndroidPhone={setAndroidPhone}
              iosTablet={iosTablet}
              setIosTablet={setIosTablet}
              androidTablet={androidTablet}
              setAndroidTablet={setAndroidTablet}
              desktop={desktop}
              setDesktop={setDesktop}
              mac={mac}
              setMac={setMac}
              linux={linux}
              setLinux={setLinux}
              handleContinue={handleContinue}
              validInputs={validInputs}
            />
          )}

          {phase === 2 && (
            <ConfigureSocialMediaPreview
              title={smTitle}
              setTitle={setSmTitle}
              subtitle={smSubtitle}
              setSubtitle={setSmSubtitle}
              pictureType={smPictureType}
              setPictureType={setSmPictureType}
              pictureLink={smLink}
              setPictureLink={setSmLink}
              files={smFile}
              setFiles={setSmFile}
              handleBackBtn={handleBackBtn}
              handleGenerateDynamicLink={handleGenerateDynamicLink}
              recaptchVerify={recaptchVerify}
            />
          )}

          {phase === 3 && (
            <GeneratedLink
              handleGEnerateNewDynamicLink={handleGEnerateNewDynamicLink}
              handleRegisterFreeAccount={handleRegisterFreeAccount}
              generatedLink={generatedLink}
              qrCode={qrCode}
            />
          )}
        </AnimatePresence>
      </DynamicLinkGeneratorcard>

      <UseCases />

    </Container>
  );
};

export default DynamicLinkGenerator;

const Container = styled.div`
  width: 100%;
  max-width: 1512px;
  margin-left: auto;
  margin-right: auto;
  z-index: 1;
  display: flex;
  flex-direction: column;
  gap: 100px;
  padding: 50px 40px;
  @media(max-width:990px){
    padding:  20px;
    gap: 50px;
  }
`;
const DescriptionWrapper = styled.div`
  display: flex;
  gap: 100px;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  overflow:hidden;
  @media (max-width: 990px) {
    flex-direction: column;
    align-items: start;
      padding: 0 ;
        gap: 50px;
  }
`;

const TitleSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 80px;
  h1 {
    font-size: 50px;
    font-weight: 700;
    line-height: 60px;
    text-align: left;
    color: ${lightThemeColors.darkColor};
    span {
      font-weight: 800;
      color: ${lightThemeColors.primaryColor};
    }
  }
     @media (max-width: 990px) {
       gap: 30px;
       h1{
       font-size:30px;
        line-height: 38px;
       }
     }
`;
const ColumnGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 14px;
  overflow:hidden;
`;
const RedirectLabel = styled.p`
  font-size: 12px;
  font-weight: 700;
  line-height: 16.8px;
  text-align: left;
  color: ${lightThemeColors.labelColor};
`;
const RedirctsList = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
flex-wrap:wrap;
`;
const RedirectItemWrapper = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
  p {
    font-size: 14px;
    font-weight: 500;
    line-height: 16.8px;
    text-align: left;
    color: ${lightThemeColors.darkColor};
  }
`;
const DescriptionSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  max-width: 500px;
  p {
    color: ${lightThemeColors.textColor};
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
  }
`;
const Line = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
`;
