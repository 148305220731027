import React from 'react'
import styled from 'styled-components';
import DynamicLinkGeneratorcard from '../components/DynamicLinkGeneratorComponents/DynamicLinkGeneratorcard';
import ContactCard from '../components/Contact/ContactCard';

const ContactUsPage = () => {
  return (
    <Container>
      <ContactCard
        title={"Contact"}
        titleSpan={"linksquared"}
        subtitle={"We’re here to help you increase the success of your product."}
        type={"support"}
      />
    </Container>
  )
}

export default ContactUsPage

const Container = styled.div`
  width: 100%;
  max-width: 1512px;
  margin-left: auto;
  margin-right: auto;
  z-index: 1;
  display: flex;
  flex-direction: column;
  gap: 100px;
  padding: 50px 40px;
  @media(max-width:990px){
    padding: 0px 20px;
    gap: 50px;
  }
`;