export const FILE = "file"
export const LINK = "link"


export const FACEBOOK = "facebook"
export const X = "x"
export const LINKEDIN = "linkedin"

export const DEFAULT_PALCEHOLDER_LINK =
    "https://appssemble-assets.s3.eu-north-1.amazonaws.com/linksquared/social-media-placeholder.jpg";


export const SERVICES_ENDPOINT = "services"
export const SQD_ENDPOINT = "sqd"
export const APPSSEMBLE_ENDPOINT = "appssemble"