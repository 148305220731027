import React from 'react'
import styled from 'styled-components'
import { lightThemeColors } from '../../assets/style/colors'
import { DEFAULT_PALCEHOLDER_LINK } from '../../constants/constants'

const LinkedInCardPreview = ({ domain, title, subtitle, img }) => {
    return (
        <Card>
            <ImageWrapper>
                {img ?
                    <img src={img} alt="social-media-preview" /> :
                    <img src={DEFAULT_PALCEHOLDER_LINK} alt="social-media-preview" />
                }
            </ImageWrapper>
            <InfoWrapper>
                <Title>{title ? title : "Dynamic link"}</Title>
                <Domain>{domain}</Domain>
            </InfoWrapper>

        </Card>
    )
}

export default LinkedInCardPreview

const Card = styled.div`
display:flex;
flex-direction:column;
max-width:343px;
max-height:260px;
overflow:hidden;
`
const InfoWrapper = styled.div`
display:flex;
flex-direction:column;
border:0.5px solid rgba(91, 112, 131, 1);
gap:5px;
padding:10px 12px;
border-radius: 0 0 15px 15px;
background:${lightThemeColors.background};

`
const ImageWrapper = styled.div`
display:flex;

overflow:hidden;
border-radius:15px 15px 0 0;
justify-content:center;
align-items:center;
img{

    width:100%;
    height:100%;
    object-fit:cover;
}
`
const Domain = styled.p`
color:${lightThemeColors.socialMediaPreviewCard.xCardDomainColor};
font-size: 9px;
font-weight: 500;
line-height: 12px;
text-transform:uppercase;
`
const Title = styled.h1`
color:${lightThemeColors.socialMediaPreviewCard.primaryColor};
font-size: 11px;
font-weight: 600;
line-height: 15px;
`
const Subtitle = styled.p`
color:${lightThemeColors.socialMediaPreviewCard.primaryColor};
font-size: 9px;
font-weight: 500;
line-height: 12px;
text-align: left;
`
