import React from "react";
import styled from "styled-components";
import { lightThemeColors } from "../../assets/style/colors";

const OptionSelectComponent = ({
  selectedOption,
  setSelectedOption,
  optionsList,
}) => {
  return (
    <Container>
      {optionsList.map((btn, index) => (
        <Optionelement
          key={index}
          onClick={() => setSelectedOption(btn.value)}
          selected={selectedOption === btn.value}
        >
          <p>{btn.text}</p>
        </Optionelement>
      ))}
    </Container>
  );
};

export default OptionSelectComponent;

const Container = styled.div`
  display: flex;
  border-radius: 5px;
  min-height: 30px;
  width: fit-content;
  align-items: center;
  overflow: hidden;
  :first-child {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  :last-child {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }
`;
const Optionelement = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px 10px;
  background: ${(props) => (props.selected ? "rgba(231, 233, 236, 1)" : "")};
  cursor: pointer;
  border: 1px solid ${"rgba(231, 233, 236, 1)"};
  min-width: 90px;
  p {
    color: ${lightThemeColors.darkColor};
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    opacity: ${(props) => (props.selected ? 1 : 0.3)};
  }
`;
