import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { lightThemeColors } from "../../assets/style/colors";
import AnimatedTextButton from "../generic/AnimatedTextButton";
import { Link, useLocation } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import { ReactComponent as XIcon } from "../../assets/icons/XIcon.svg";
import { useCookies } from "react-cookie";
import PrimaryButton from "../generic/PrimaryButton";
const BlueHeader = () => {
  const location = useLocation();

  const [open, setOpen] = useState(true);

  const [cookies, setCookie] = useCookies("blueHeader");

  const handleCloseHeader = () => {
    setCookie("blueHeader", false);
    setOpen(false);
  };

  useEffect(() => {
    if (cookies.blueHeader !== undefined) {
      setOpen(cookies.blueHeader);
    }
  }, []);

  return (
    <AnimatePresence>
      {open && location.pathname !== "/create" && (
        <motion.div
          initial={{ height: 0 }}
          animate={{ height: "40px" }}
          exit={{ height: 0 }}
          transition={{ duration: 0.4 }}
        >
          <BlueHeaderContainer>
            <Link style={{ textDecoration: "none" }} to={"/create"}>
              <PrimaryButton
                styled={{ color: lightThemeColors.background, width: "100%" }}
                arrow
                bgVariant={"transparent"}
              >
                <p>
                  Try out our free dynamic link generator <br />
                  (no registration needed)
                </p>
              </PrimaryButton>
            </Link>
            <CloseBtn onClick={() => handleCloseHeader()}>
              <XIcon />
            </CloseBtn>
          </BlueHeaderContainer>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default BlueHeader;
const BlueHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 40px;
  max-height: 40px;
  height: 100%;
  width: 100%;
  background: ${lightThemeColors.primaryColor};
  p {
    color: ${lightThemeColors.background};
    font-size: 14px;
    font-weight: 400;
    line-height: 16.8px;
    text-align: left;
    br {
      display: none;
    }
    @media (max-width: 550px) {
      font-size: 10px;
      line-height: 12px;
      text-align: center;
      br {
        display: flex;
      }
    }
  }
  position: relative;
`;
const CloseBtn = styled.button`
  position: absolute;
  right: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: none;
  background: none;
  color: ${lightThemeColors.background};
  @media (max-width: 550px) {
    right: 15px;
    svg {
      max-height: 10px;
    }
  }
`;
