import React, { useState } from "react";
import styled from "styled-components";
import { lightThemeColors } from "../../assets/style/colors";
import { ReactComponent as Arrow } from "../../assets/icons/forward-arrow-small.svg";
import { motion } from "framer-motion";

const PrimaryButton = ({
  icon,
  text,
  disabled,
  onClick,
  styled,
  bgVariant,
  children,
  arrow,
}) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <CustomButton
      disabled={disabled}
      onClick={onClick}
      style={styled}
      bgVariant={bgVariant}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {icon}
      {text}
      {children}
      {arrow && (
        <motion.div
          style={{ display: "flex", alignItems: "center" }}
          initial={{ translateX: 0 }}
          animate={
            isHovered
              ? {
                translateX: [0, "12px", "-12px", 0],
                opacity: [1, 0, 0, 1],
                transformStyle: "preserve-3d",
              }
              : {
                translateX: 0,
                opacity: 1,
                transformStyle: "preserve-3d",
              }
          }
          transition={{
            duration: 0.5,
            type: "keyframes",
            repeat: false,
            ease: "linear",
          }}
        >
          <Arrow />
        </motion.div>
      )}


    </CustomButton>
  );
};

export default PrimaryButton;

const CustomButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  gap: 6px;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: center;
  color: white;
  background: ${(props) =>
    props.bgVariant ? props.bgVariant : lightThemeColors.primaryColor};
  padding: 8px 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  &:disabled {
    opacity: 0.3;
  }
  svg {
    height: 16px;
    width: 16px;
  }
`;
