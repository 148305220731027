import "https://cdn.jsdelivr.net/gh/orestbida/cookieconsent@3.0.1/dist/cookieconsent.umd.js";
import { useEffect } from "react";

import * as CookieConsent from "vanilla-cookieconsent";
import "vanilla-cookieconsent/dist/cookieconsent.css";
import ReactGA from "react-ga4";

const TRACKING_ID = "G-YZX21REZ7F";

export default function CookieConsentElement() {
  useEffect(() => {
    // Enable dark mode

    CookieConsent.run({
      onConsent: function () {
        if (CookieConsent.acceptedCategory("analytics")) {
          // Analytics category enabled
          ReactGA.initialize(TRACKING_ID);
        }
      },
      guiOptions: {
        consentModal: {
          layout: "box",
          position: "bottom right",
          equalWeightButtons: false,
          flipButtons: false,
        },
        preferencesModal: {
          layout: "box",
          position: "right",
          equalWeightButtons: false,
          flipButtons: false,
        },
      },
      categories: {
        necessary: {
          readOnly: true,
        },
        analytics: {},
      },
      language: {
        default: "en",
        autoDetect: "browser",
        translations: {
          en: {
            consentModal: {
              description:
                "Our website uses cookies to enhance your browsing experience, provide personalized content, and analyze site traffic.",
              acceptAllBtn: "Accept all",
              acceptNecessaryBtn: "Reject all",
              showPreferencesBtn: "Manage preferences",
              footer:
                '<a href="/privacy">Privacy Policy</a>\n<a href="/terms">Terms and conditions</a>',
            },
            preferencesModal: {
              title: "Consent Preferences Center",
              acceptAllBtn: "Accept all",
              acceptNecessaryBtn: "Reject all",
              savePreferencesBtn: "Save preferences",
              closeIconLabel: "Close modal",
              serviceCounterLabel: "Service|Services",
              sections: [
                {
                  title: "Cookie Usage",
                  description:
                    "Our website uses cookies to personalize content, improve user experience, and analyze traffic. Cookies help us understand your preferences and improve our services.",
                },
                {
                  title:
                    'Strictly Necessary Cookies <span class="pm__badge">Always Enabled</span>',
                  description:
                    "These cookies are essential for the website to function properly. They enable basic features such as page navigation, secure access to certain areas, and other necessary functionalities.",
                  linkedCategory: "necessary",
                },
                {
                  title: "Analytics Cookies",
                  description:
                    "Analytics cookies help us understand how visitors interact with our website by collecting and reporting information anonymously. These cookies provide insights into website performance, user behavior, and areas for improvement, enabling us to enhance the overall user experience.",
                  linkedCategory: "analytics",
                },
                {
                  title: "More information",
                  description:
                    'For any query in relation to my policy on cookies and your choices, please <a class="cc__link" href="/contact">contact us</a>.',
                },
              ],
            },
          },
        },
      },
    });
  }, []);

  return null;
}
