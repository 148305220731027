
import axios from "axios";
import LocalStorage from "../helpers/LocalStorage";
import { APPSSEMBLE_ENDPOINT, SERVICES_ENDPOINT, SQD_ENDPOINT } from "../constants/constants";




const SQD_URL = process.env.REACT_APP_API_SQD_URL;
const SERVICE_URL = process.env.REACT_APP_API_SERVICE_URL
const APPSSEMBLE_URL = process.env.REACT_APP_API_URL


const buildConfig = (endpoint, method, path, data = undefined, controller = null, onProgress = null) => {

    var URL = SERVICE_URL;

    switch (endpoint) {
        case SERVICES_ENDPOINT:
            URL = SERVICE_URL;
            break;
        case SQD_ENDPOINT:
            URL = SQD_URL;
            break;
        case APPSSEMBLE_ENDPOINT:
            URL = APPSSEMBLE_URL;
            break;

        default:
            break;
    }

    var config = {
        cancelToken: controller?.token,
        method: method,
        url: `${URL}${path}`,
        headers: {
            // "Access-Control-Allow-Origin": true,
            Authorization: `Bearer ${LocalStorage.getAuthenticationToken()}`,
        },

        onUploadProgress: function (progressEvent) {
            const { loaded, total } = progressEvent;
            let precentage = Math.floor((loaded * 100) / total);
            if (onProgress != null) {
                onProgress(precentage)
            }
        },
        data: data,
    };



    return config;
};

const makeRequest = (config, onSuccess, onError, retry) => {
    axios(config)
        .then(function (response) {
            onSuccess(response);
        })
        .catch(function (error) {
            if (error.code == "ERR_CANCELED") {
                return;
            }
            console.log("ERR", error);
            if (error.response.status === 401 && retry === true) {
                // refreshToken(
                //     LocalStorage.getRefreshToken(),
                //     LocalStorage.getAuthenticationToken(),
                //     (response) => {
                //         let res = JSON.parse(response);
                //         LocalStorage.setAuthenthicationToken(res.access_token)
                //         LocalStorage.setRefreshToken(res.refresh_token)
                //         makeRequest(config, onSuccess, onError, (retry = false));
                //     },
                //     (error) => {
                //         onError(error);
                //     }
                // )
            } else {
                onError(error);
            }
        });
};
export const GET = (
    endpoint,
    path,
    onSuccess,
    onError,
    retry = true,
    controller = null,
    customUrl = null
) => {
    controller?.cancel();
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const config = buildConfig(endpoint, "GET", path, undefined, source, customUrl);
    makeRequest(config, onSuccess, onError, retry);

    return source;
};
export const POST = (
    endpoint,
    path,
    data,
    onSucces,
    onError,
    retry = true,
    controller = null,
    onProgress = null,
) => {
    controller?.cancel();

    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const config = buildConfig(endpoint, "POST", path, data, source, onProgress);
    makeRequest(config, onSucces, onError, retry);

    return source;
};
export const PATCH = (endpoint, path, data, onSucces, onError, retry = true,) => {
    const config = buildConfig(endpoint, "PATCH", path, data);
    makeRequest(config, onSucces, onError, retry);
};
export const PUT = (endpoint, path, data, onSucces, onError, retry = true,) => {
    const config = buildConfig(endpoint, "PUT", path, data);
    makeRequest(config, onSucces, onError, retry);
};
export const DELETE = (endpoint, path, onSuccess, onError, retry = true,) => {
    const config = buildConfig(endpoint, "DELETE", path);
    makeRequest(config, onSuccess, onError, retry);
};
