import React from "react";
import styled from "styled-components";
import { lightThemeColors } from "../../assets/style/colors";
import { ReactComponent as Asset1 } from "../../assets/imgs/fold4Asset1.svg";
import { ReactComponent as Asset2 } from "../../assets/imgs/fold4Asset2.svg";
import { ReactComponent as Asset3 } from "../../assets/imgs/fold4Asset3.svg";
import { useOutletContext } from "react-router-dom";
import { motion } from "framer-motion";

const Fold4 = () => {
  return (
    <FullPageContainer>
      <Container id="benefits">
        <Line style={{ minHeight: "unset" }}>
          <Title>
            Linksquared{" "}
            <span>
              {" "}
              boosts conversions, ads ROI,
              <br /> and retention
            </span>
          </Title>
        </Line>

        <Line>
          <CardWrapper
            as={motion.div}
            initial={{ height: 0 }}
            whileInView={{ height: "100%" }}
            transition={{ duration: 1, type: "spring" }}
            viewport={{ once: true }}
          >
            <Asset1 />
          </CardWrapper>

          <TextWrapper
            as={motion.div}
            initial={{ translateX: "99%" }}
            whileInView={{ translateX: 0 }}
            transition={{ duration: 1, type: "spring" }}
            viewport={{ once: true }}
          >
            <h1>Boost your ROI on paid ads</h1>
            <p>
              Boost ROI and validate your ad spend. Track link performance using
              metrics like installs, reinstalls, reactivations, views, app
              opens, and session time.
            </p>
          </TextWrapper>
        </Line>

        <SeparatorLine />
        <Line reverse>
          <CardWrapper
            as={motion.div}
            initial={{ height: 0 }}
            whileInView={{ height: "100%" }}
            transition={{ duration: 0.6, type: "spring" }}
            viewport={{ once: true }}
          >
            <Asset2 />
          </CardWrapper>

          <TextWrapper
            as={motion.div}
            initial={{ translateX: "-99%" }}
            whileInView={{ translateX: 0 }}
            transition={{ duration: 1, type: "spring" }}
            viewport={{ once: true }}
          >
            <h1>Increase retention and engagement</h1>
            <p>
              Linksquared enhances retention by guiding users to relevant app
              content, reducing drop-offs and improving engagement. This boosts
              ROI through targeted interactions and easier navigation.
            </p>
          </TextWrapper>
        </Line>

        <SeparatorLine />

        <Line>
          <CardWrapper
            as={motion.div}
            initial={{ height: 0 }}
            whileInView={{ height: "100%" }}
            transition={{ duration: 0.6, type: "spring" }}
            viewport={{ once: true }}
          >
            <Asset3 />
          </CardWrapper>
          <TextWrapper
            as={motion.div}
            initial={{ translateX: "99%" }}
            whileInView={{ translateX: 0 }}
            transition={{ duration: 1, type: "spring" }}
            viewport={{ once: true }}
          >
            <h1>Increase conversions</h1>
            <p>
              Create, manage, and track links effortlessly to enhance
              conversions without technical skills. Linksquared simplifies
              marketing and maximizes effectiveness.
            </p>
          </TextWrapper>
        </Line>
      </Container>
    </FullPageContainer>
  );
};

export default Fold4;

const FullPageContainer = styled.div`
  display: flex;
  width: 100%;
  background: ${lightThemeColors.background};
  position: relative;
`;

const Container = styled.div`
  width: 100%;
  max-width: 1512px;
  margin-left: auto;
  margin-right: auto;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: start;
  padding: 80px;
  gap: 80px;
  @media (max-width: 990px) {
    padding: 20px;
    gap: 20px;
  }
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  gap: 16px;

  background: ${lightThemeColors.linkGeneratorBg};
  padding: 40px 60px;
  border-radius: 10px;
  margin: 40px 0;
  @media (max-width: 990px) {
    padding: 30px 20px;
  }

  h1 {
    color: ${lightThemeColors.darkColor};
    font-size: 25px;
    font-weight: 700;
    line-height: 30px;
    text-align: left;

    @media (max-width: 990px) {
      font-size: 20px;
    }
  }

  p {
    color: rgba(37, 34, 34, 0.7);
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;

    @media (max-width: 990px) {
      font-size: 16px;
    }
  }
  @media (max-width: 990px) {
    width: 100%;

  }
`;

const Title = styled.h1`
  color: ${lightThemeColors.darkColor};
  font-size: 40px;
  font-weight: 600;
  line-height: 48px;
  margin-bottom: 40px;
  span {
    font-weight: 800;
    color: ${lightThemeColors.primaryColor};
  }
  @media (max-width: 990px) {
    br {
      display: none;
    }
    font-size: 30px;
    line-height: 40px;
  }
`;

const CardWrapper = styled.div`
  display: flex;
  justify-content: center; // Center horizontally
  align-items: center; // Center vertically
  width: 50%;
  overflow: hidden;
  height: 100%;
  svg {
    display: flex;
    width: 70%;
    align-items: end;
    height: auto; // Ensure the aspect ratio is maintained
    @media (max-width: 990px) {
      width: 100%;
      height:100%;
    }
  }

 @media (max-width: 990px) {
 height:100%;

    }
  @media (max-width: 550px) {
 width:100%;

    }

`;

const Line = styled.section`
  display: flex;
  align-items: center;
  gap: 10px;

  width: 100%;
  overflow: hidden;
  flex-direction: ${(props) => (props.reverse ? "row-reverse" : "initial")};
  min-height: 265px;

  @media (max-width: 990px) {
    gap: 10px;
    flex-direction: column;
  }
`;
const SeparatorLine = styled.div`
  display: flex;
  background: rgba(246, 244, 242, 1);
  min-height: 2px;
  width: 100%;
  margin: 0px 0;
  @media (max-width: 990px) {
    margin: 0;
  }
`;
