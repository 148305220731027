import React, { useRef, useState } from "react";

import Slider from "react-slick";

import styled from "styled-components";
import { ReactComponent as Arrow } from "../../assets/icons/arrowRightIcon.svg";
import { lightThemeColors } from "../../assets/style/colors";
import CarouselItem from "../Fold3/CarouselItem";
import SubscriptionPlanCard from "./SubscriptionPlanCard";
import { useNavigate } from "react-router-dom";

const PricingCarousel = () => {
    const ref = useRef();
    const [slideIndex, setSlideIndex] = useState(1);
    const navigate = useNavigate()
    const handleGetStartedForFree = () => {

        window.location.href = "https://app.linksquared.io/register"
    }
    const handleContactSales = () => {
        navigate("/sales")
    }
    const slides = [
        {
            plan: "free",
            handleGetStartedForFree: handleGetStartedForFree
        },
        {
            plan: "scale_up",
            handleGetStartedForFree: handleGetStartedForFree
        },
        {
            plan: "enterprise",
            handleContactSales: handleContactSales
        },

    ];

    var settings = {
        dots: false,
        arrows: false,
        infinite: false,
        speed: 400,
        centerMode: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        beforeChange: (current, next) => {
            setSlideIndex(next);
        },
        responsive: [
            {
                breakpoint: 550,
                settings: {
                    centerPadding: "0px",
                }
            }
        ]
    };

    return (
        <Container>
            <Slider {...settings} ref={ref}>
                {slides.map((slide, index) => (

                    <SubscriptionPlanCard
                        key={index}
                        plan={slide.plan}
                        handleGetStartedForFree={slide.handleGetStartedForFree}
                        handleContactSales={slide.handleContactSales}
                    />

                ))}
            </Slider>

            <NavigationBtnWrapper>
                <CustomArrowBtn
                    disabled={slideIndex === 0}
                    onClick={() => ref.current.slickPrev()}
                >
                    <Arrow style={{ rotate: "180deg" }} />
                </CustomArrowBtn>
                <CustomArrowBtn
                    disabled={slideIndex === slides.length - 1}
                    onClick={() => ref.current.slickNext()}
                >
                    <Arrow />
                </CustomArrowBtn>
            </NavigationBtnWrapper>
        </Container >
    );
};

export default PricingCarousel;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
width:100%;
`;
const NavigationBtnWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  padding-left: 70px;
  @media(max-width:990px){
    padding-left: 20px;
  }
`;
const CustomArrowBtn = styled.button`
  background: none;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  padding: 10px 10px;
  background: ${lightThemeColors.darkBannerBg};
  color: ${lightThemeColors.background};
  cursor: pointer;
  border: none;
  svg {
    height: auto;
    width: 16px;
  }

  &:disabled {
    opacity: 0.3;
    background: ${lightThemeColors.background};
    color: ${lightThemeColors.textColor};
    cursor: inherit;
  }
`;
