import React from "react";
import styled from "styled-components";
import { lightThemeColors } from "../../assets/style/colors";
import Carousel from "./Carousel";

const Fold3 = () => {
  return (
    <FullPageContainer>
      <Container>
        <Carousel />
      </Container>
    </FullPageContainer>
  );
};

export default Fold3;

const FullPageContainer = styled.div`
  display: flex;
  width: 100%;
  background: ${lightThemeColors.linkGeneratorBg};
  position: relative;
`;

const Container = styled.div`
  width: 100%;
  // max-width: 1512px;
  margin-left: auto;
  margin-right: auto;
  z-index: 1;
  display: flex;
  flex-direction: column;
  padding: 80px 0;
  gap: 40px;
      @media(max-width:990px){
    gap: 30px;
padding:20px;
  }

`;
