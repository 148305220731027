import React from "react";
import styled from "styled-components";
import { lightThemeColors } from "../../assets/style/colors";
import { ReactComponent as RightIcon } from "../../assets/icons/arrowRightIcon.svg";

const SmallScreenMenuItem = ({ text, onClick }) => {
  return (
    <Container onClick={onClick}>
      <p>{text}</p>
      <RightIcon />
    </Container>
  );
};

export default SmallScreenMenuItem;
const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgba(118, 114, 113, 0.1);
  height: fit-content;
  padding: 20px 0px;
  width: 100%;
  gap: 30px;
  cursor: pointer;
  p {
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
    font-size: 16px;
    font-weight: 400;
    line-height: 18px;
  }
  svg {
    max-width: 18px;
    max-height: 14px;
  }
`;
