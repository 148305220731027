export const lightThemeColors = {
  darkColor: "rgba(48, 44, 44, 1)",
  darkColorFaded: "rgba(48, 44, 44, 0.5)",
  darkColorFullFaded: "rgba(47, 47, 47, 0.1)",
  primaryColor: "rgba(42, 134, 255, 1)",
  primaryColorFaded: "rgba(42, 134, 255, 0.3)",
  background: "rgba(255, 255, 255, 1)",
  fold1Bg: "rgba(244, 249, 255, 1)",
  footerBg: "rgba(0, 0, 0, 1)",
  borderColor: "rgba(0, 0, 0, 0.1)",
  cardBg: "rgba(239, 242, 245, 1)",
  validGreen: "rgba(112, 203, 151, 1)",
  validGreenFaded: "rgba(112, 203, 151, 0.2)",
  inputFieldBorder: "rgba(19, 36, 65, 0.1)",
  labelColor: "rgba(118, 114, 113, 0.6)",
  textColor: "rgba(118, 114, 113, 1)",
  linkGeneratorBg: "rgba(246, 244, 242, 1)",
  inputFieldLabelColor: "rgba(120, 118, 118, 1)",
  cardBgDark: "rgba(52, 52, 51, 1)",
  cardTextColorDark: "rgba(255, 255, 255, 0.5)",
  darkBannerBg: "rgba(36, 36, 36, 1)",
  socialMediaPreviewCard: {
    buttonBg: "rgba(42, 134, 255, 0.03)",
    buttonBgSelected: "rgba(227, 237, 249, 1)",
    secondColor: "rgba(96, 103, 112, 1)",
    primaryColor: "rgba(29, 33, 41, 1)",
    xCardInfoBg: "rgba(242, 243, 245, 1)",
    xCardDomainColor: "rgba(181, 185, 190, 1)",
  },
};
