import toast from "react-hot-toast";
import styled from "styled-components";
import { ReactComponent as CustomSuccessIcon } from "../assets/icons/successIconRound.svg"
import { lightThemeColors } from "../assets/style/colors";
export const showGenericError = () => {
    toast.error("Something went wrong, please try again")
};

export const showSuccessNotification = (message) => {
    toast.success(message);
};

export const showErrorNotification = (message) => {
    toast.error(message);
};

export const customSuccessNotification = (message) => {
    toast.custom(
        <CustomNotification>
            <CustomSuccessIcon />
            <p>{message}</p>
        </CustomNotification>, {

    })
}
export const showErrorNotificationWithLink = (message, onSuccess) => {
    toast.error((t) => {
        return (
            <div
                onClick={() => {
                    onSuccess();
                    toast.dismiss(t.id);
                }}
            >
                {message}
            </div>
        );
    });
};

const CustomNotification = styled.div`
display:flex;
align-items:center;
gap:20px;
background:${lightThemeColors.linkGeneratorBg};
padding:15px;
border-radius:10px;
width:fit-content;
svg{
max-height:22px;
min-height:22px;
color:${lightThemeColors.darkColor};
}
p{
max-width:300px;
font-weight:500;
font-size:18px;
line-height:22px;
color:${lightThemeColors.darkColor};
}
box-shadow: 0px 0px 20px 0.1px  ${lightThemeColors.labelColor};

`
